import { Link, useTheme } from "@mui/material";
import VuiTypography from "components/VuiTypography";
import { formatAddress } from "utils/formatter";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
export const EtherscanLink = ({ path = "", name = "" }) => {
  const baseUrl = "https://etherscan.io/";
  const url = baseUrl + path;
  const theme = useTheme();
  return (
    <>
      <VuiTypography variant="subtleHeading">
        <Link
          style={{ textDecoration: "none" }}
          href={url}
          target="_blank"
          sx={{ color: ` ${theme.palette.secondary.light}` }}
        >
          {name}
        </Link>
        {/* <OpenInNewIcon /> */}
      </VuiTypography>
    </>
  );
};
