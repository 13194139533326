const palette = {
  primary: {
    main: "#1976d2",
    light: "#42a5f5",
    dark: "#1565c0",
    contrastText: "#fff",
  },
  secondary: {
    main: "#1976d2",
    light: "#42a5f5",
    dark: "#1565c0",
    contrastText: "#fff",
  },
  error: {
    main: "",
    light: "",
    dark: "",
  },
  warning: {
    main: "",
    light: "",
    dark: "",
  },
  info: {
    main: "",
    light: "",
    dark: "",
  },
  success: {
    main: "",
    light: "",
    dark: "",
  },
};

export const lightMode = {
  background: {
    default: "white",
    paper: "white",
  },
  primary: {
    main: "#3E1929",
    light: "#42a5f5",
    dark: "#1565c0",
    contrastText: "#E0E1DD",
  },
  secondary: {
    main: "#89D2DC",
    muted: "#007EA7",
    light: "#2E5266",
    dark: "#1565c0",
    contrastText: "#fff",
  },
  accent: {
    main: "#89D2DC",
    positive: "#89AAE6",
    negative: "#F06C9B",
    contrastText: "#fff",
  },
};

export const darkMode = {
  background: {
    // default: "#121212",
    // paper: "#121212",
    default: "#0D1317",
    paper: "#0D1317",
  },
  primary: {
    main: "#1F0318",
    // light: "#42a5f5",
    // dark: "#41EAD4",
    contrastText: "#fff",
  },
  secondary: {
    main: "#0D1317",
    muted: "#3D518C",
    // light: "#42a5f5",
    // dark: "#1565c0",
    contrastText: "#fff",
  },
  accent: {
    main: "#19297C",
    // light: "#42a5f5",
    // dark: "#1565c0",
    positive: "#89AAE6",
    negative: "#DA4167",
    contrastText: "#fff",
  },
};
// export const darkMode = {
//   background: {
//     default: "#0B0205",
//     paper: "#0B0205",
//   },
//   primary: {
//     main: "#724C9D",
//     // light: "#42a5f5",
//     // dark: "#41EAD4",
//     contrastText: "#fff",
//   },
//   secondary: {
//     main: "#2C1B47",
//     muted: "#3D518C",
//     // light: "#42a5f5",
//     // dark: "#1565c0",
//     contrastText: "#fff",
//   },
//   accent: {
//     main: "#2C1B47",
//     // light: "#42a5f5",
//     // dark: "#1565c0",
//     positive: "#89AAE6",
//     negative: "#D25B5B",
//     contrastText: "#fff",
//   },
// };
