import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

export function DetailCard({ title, subtitle }) {
  return (
    <Card>
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <VuiBox lineHeight={1}>
            <VuiTypography variant="p">{title}</VuiTypography>
          </VuiBox>
          <VuiBox lineHeight={1}>
            <VuiTypography variant="subtleHeading">{subtitle} </VuiTypography>
          </VuiBox>
        </Grid>
      </Grid>
    </Card>
  );
}
