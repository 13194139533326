import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";

import { Grid, Card } from "@mui/material";
import { TopHolderV2Context } from "contexts/TopHolderV2Context";
import { WalletProfileContext } from "contexts/WalletProfileContext";
import { ethers } from "ethers";
import { EtherscanLink } from "modules/common/EtherscanLink";
import { RankIcon } from "modules/common/RankIcon";
import { getTopHolderRank } from "utils/getTopHolderRank";
import { WealthIcon } from "modules/common/WealthIcon";
import { TokenContext } from "contexts/TokenContext";
import { useContext } from "react";
import { TopHoldersContext } from "contexts/TopHoldersContext";
import { WalletProfileStub } from "modules/common/WalletProfileStub";

export default function ActivityList({ activityData }) {
  const { title, data } = activityData;
  const thContext = useContext(TopHoldersContext);
  const walletContext = useContext(WalletProfileContext);
  const tokenContext = useContext(TokenContext);
  const { contract_ticker_symbol } = tokenContext;
  return (
    <Card>
      <List>
        <h3>{title}</h3>
        <Grid container>
          {data.map((d) => {
            const rank = getTopHolderRank(thContext, d.address);
            const wallet = walletContext[d.address]
              ? walletContext[d.address]
              : false;
            return (
              <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={12} lg={6}>
                  <WalletProfileStub address={d.address} index={rank} />
                </Grid>
                <Grid item xs={6}>
                  <ListItem>
                    <ListItemText
                      primary={`${d.net > 0 ? "+" : ""} ${ethers.utils.commify(
                        d.net.toFixed(2)
                      )} ${contract_ticker_symbol}`}
                      secondary={`${d.count} transfers`}
                      sx={{
                        color: d.net > 0 ? "green" : "red",
                      }}
                    />
                  </ListItem>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </List>
    </Card>
  );
}
