import { ethers } from "ethers";

export const createRadialChartOptions = (labels, theme) => {
  return {
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            show: false,
          },
        },
      },
    ],
    labels: labels,
    legend: {
      show: true,
      floating: false,
      // fontSize: "16px",
      position: "left",
      offsetX: 0,
      offsetY: 0,
      // labels: {
      //   useSeriesColors: true,
      // },
      markers: {
        size: 0,
      },
      formatter: function (seriesName, opts) {
        // console.log(opts, " opts ");
        // console.log(seriesName, " seriesName ");
        return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
      },
      itemMargin: {
        vertical: 3,
      },
    },
    plotOptions: {
      radialBar: {
        track: {
          // background: "black !important",
          strokeWidth: 100,
        },
        offsetY: 0,
        // startAngle: 0,
        // endAngle: 270,
        hollow: {
          margin: 5,
          size: 50,
          background: "transparent",
        },
      },
    },
  };
};
