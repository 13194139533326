import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import VuiTypography from "components/VuiTypography";
import { Link } from "react-router-dom";
import { Button, Grid, useTheme } from "@mui/material";
import { GlobalContext } from "contexts/GlobalContext";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDiscord,
  faMedium,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";

function HomeIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </SvgIcon>
  );
}

export const Navbar = () => {
  const { themeMode, themeToggleHandler } = React.useContext(GlobalContext);
  const theme = useTheme();
  return (
    <Box sx={{ flexGrow: 1, backgroundColor: theme.palette.primary.main }}>
      <AppBar
        position="static"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <Grid container>
            <Grid item xs={12}>
              <Link
                to={{
                  pathname: `/`,
                }}
              >
                <HomeIcon color="secondary" fontSize="large" edge="start" />
              </Link>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
