/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Vision UI Dashboard React Base Styles
import borders from "../../base/borders";
import boxShadows from "../../base/boxShadows";

// Vision UI Dashboard React Helper Function

const { borderRadius } = borders;
const { xxl, md, sm, xs } = boxShadows;

export default {
  styleOverrides: {
    root: ({ theme }) => ({
      display: "flex",
      flexDirection: "column",
      // background: theme.palette.background.surface,
      // background: theme.palette.snow.default,
      // backdropFilter: "blur(120px)",
      position: "relative",
      minWidth: 0,
      padding: "10px",
      margin: "10px",
      wordWrap: "break-word",
      // backgroundClip: "border-box",
      // border: `1px solid ${theme.palette.secondary.default}`,
      borderRadius: borderRadius.md,
      boxShadow: sm,
    }),
  },
};
