import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";

import { Grid, Card, IconButton } from "@mui/material";

import { ethers } from "ethers";
import { EtherscanLink } from "modules/common/EtherscanLink";
import { RankIcon } from "modules/common/RankIcon";
import { WealthIcon } from "modules/common/WealthIcon";
import { TokenContext } from "contexts/TokenContext";
import { useContext } from "react";
import { timeSince } from "utils/timeSince";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { WalletProfileStub } from "modules/common/WalletProfileStub";
export default function TopHolderList({ topHolders }) {
  const [open, setOpen] = React.useState(false);
  const handleToggle = () => {
    setOpen(!open);
  };
  const tokenContext = useContext(TokenContext);
  return (
    <Card>
      <List>
        <h3>Top Holders</h3>
        <Grid container>
          {topHolders.map((holder, index) => {
            const {
              walletProfile,
              address,
              balance,
              last_transferred_at,
              history,
            } = holder;
            const { name, assetCount, portfolioValue, wealthCategory, tag } =
              walletProfile;
            return (
              <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={12} lg={6}>
                  <WalletProfileStub address={address} index={index} />
                </Grid>
                {history ? (
                  <>
                    <Grid item xs={3}>
                      <ListItem>
                        <ListItemText
                          primary={`${ethers.utils.commify(
                            balance.toFixed()
                          )} ${tokenContext.contract_ticker_symbol}`}
                          secondary={`Last Seen ${timeSince(
                            last_transferred_at
                          )}`}
                        />
                      </ListItem>
                    </Grid>
                    <Grid item xs={3}>
                      <ListItem>
                        {history.changeInBalance === 0 ? (
                          <ListItemText primary={``} secondary={``} />
                        ) : (
                          <ListItemText
                            primary={`${
                              history.changeInBalance > 0 ? `+` : ``
                            } ${ethers.utils.commify(
                              history.changeInBalance.toFixed(6)
                            )} ${tokenContext.contract_ticker_symbol}`}
                            secondary={`In the last 24 hours`}
                            sx={{
                              color:
                                history.changeInBalance > 0 ? "green" : "red",
                            }}
                          />
                        )}
                      </ListItem>
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={6}>
                    <ListItem>
                      <ListItemText
                        primary={`${ethers.utils.commify(balance.toFixed())} ${
                          tokenContext.contract_ticker_symbol
                        }`}
                        secondary={`Last Seen ${timeSince(
                          last_transferred_at
                        )}`}
                      />
                    </ListItem>
                  </Grid>
                )}
              </Grid>
            );
          })}
        </Grid>
      </List>
    </Card>
  );
}
