/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Vision UI Dashboard React base styles
import typography from "../../base/typography";
import borders from "../../base/borders";
import colors from "../../base/colors";

// Vision UI Dashboard React helper functions
import pxToRem from "../../functions/pxToRem";

const { size, fontWeightRegular, fontSizeRegular } = typography;
const { borderRadius } = borders;

export default {
  styleOverrides: {
    root: ({ theme }) => ({
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      textAlign: "center",
      maxWidth: "unset !important",
      minWidth: "150px",
      minHeight: "35px",
      fontSize: fontSizeRegular,
      fontWeight: 500,
      textTransform: "none",
      lineHeight: "inherit",
      padding: `${pxToRem(10)} !important`,
      borderRadius: borderRadius.md,
      opacity: "1 !important",
      // color: `${theme.palette.primary.contrastText} !important`,

      "& .material-icons, .material-icons-round": {
        marginBottom: "0 !important",
        marginRight: pxToRem(4),
      },
      "&.Mui-selected": {
        color: `${theme.palette.primary.contrastText} !important`,
      },
      "& svg": {
        marginBottom: "0 !important",
        color: `#fff !important`,
        marginRight: pxToRem(6),
      },
    }),

    labelIcon: {
      paddingTop: pxToRem(4),
    },
  },
};
