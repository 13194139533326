import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Outlet } from "react-router-dom";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Navbar } from "Navbar";
import { AppRouter } from "routes";
import GlobalContextProvider from "contexts/GlobalContext";

const Application = () => {
  return (
    <GlobalContextProvider>
      <App>
        <BrowserRouter>
          <Navbar />
          <AppRouter />
          <Outlet />
        </BrowserRouter>
      </App>
    </GlobalContextProvider>
  );
};

ReactDOM.createRoot(document.getElementById("root")).render(<Application />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
