import * as React from "react";
import { Grid, LinearProgress, Container, Skeleton } from "@mui/material";
import useAxiosV2 from "../../hooks/useAxios";
import { useParams } from "react-router";

import { TopHoldersContext } from "contexts/TopHoldersContext";
import { TokenInfo } from "modules/token/TokenInfo";
import { SimpleTabs } from "modules/common/SimpleTabs";
import { TokenReport } from "modules/tokenReport/TokenReport";
import { TokenContext } from "contexts/TokenContext";
import TopHolderList from "./TopHolderList";
import { TopHolderInfo } from "modules/token/TopHolderInfo";
import { ActivityReport } from "modules/tokenReport/ActivityReport";
import { WalletProfileContext } from "contexts/WalletProfileContext";
import { HoldingsReport } from "modules/tokenReport/HoldingsReport";
import { TopHoldersBreakdownChart } from "./TopHoldersBreakdownChart";

const createTabs = (topHolders, tokenAddress) => {
  const tabs = [
    {
      label: "Top Holders",
      Component: <TopHolderList topHolders={topHolders} />,
    },
    {
      label: "Holdings",
      Component: <HoldingsReport tokenAddress={tokenAddress} />,
    },

    // {
    //   label: "Activity - 1D",
    //   Component: <ActivityReport tokenAddress={tokenAddress} interval={1} />,
    // },
    {
      label: "Activity - 7D",
      Component: <ActivityReport tokenAddress={tokenAddress} interval={7} />,
    },
    {
      label: "Activity - 30D",
      Component: <ActivityReport tokenAddress={tokenAddress} interval={30} />,
    },
  ];
  return tabs;
};

export const TokenDashboardV2 = (props) => {
  const { tokenAddress } = useParams();
  const { response, error, loading } = useAxiosV2({
    axiosInstance: {},
    method: "GET",
    url: `/getTopHolderReport/${tokenAddress}`,
  });

  if (loading) {
    return (
      <Container>
        <Grid container>
          <LinearProgress />
          <Grid item xs={12}>
            <Skeleton
              variant="rounded"
              width="100%"
              height="100px"
              animation="wave"
            />
          </Grid>
        </Grid>
      </Container>
    );
  }
  if (response) {
    // console.log(response, " top holder response");
    // console.log(response, "top holder report response ");

    let {
      topHolders,
      contract_address,
      contract_name,
      contract_decimals,
      contract_ticker_symbol,
      logo_url,
      total_supply,
      createdAt,
      wealthClassification,
      topHolderControl,
      walletProfiles,
    } = response;

    const tokenInfo = {
      contract_name,
      contract_address,
      contract_decimals,
      contract_ticker_symbol,
      logo_url,
      total_supply,

      createdAt,
    };

    const topHolderData = {
      wealthClassification,
      topHolderControl,
      topHolders,
    };

    const thContext = topHolders.sort((a, b) => b.balance - a.balance);
    // console.log(thContext, " context dashboard");
    return (
      <TokenContext.Provider value={tokenInfo}>
        <TopHoldersContext.Provider value={thContext}>
          <WalletProfileContext.Provider value={walletProfiles}>
            <Container>
              <Grid container>
                <Grid item xs={12}>
                  <TokenInfo tokenInfo={tokenInfo} />
                </Grid>
                <Grid item xs={12}>
                  <TopHolderInfo topHolderData={topHolderData} />
                </Grid>
                <Grid item xs={12}>
                  <TopHoldersBreakdownChart
                    topHolders={topHolders}
                    totalSupply={total_supply}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SimpleTabs tabs={createTabs(topHolders, tokenAddress)} />
                </Grid>
              </Grid>
            </Container>
          </WalletProfileContext.Provider>
        </TopHoldersContext.Provider>
      </TokenContext.Provider>
    );
  }
};
