import { Grid } from "@mui/material";
import { TopHolderControl } from "modules/tokenReport/TopHolderControl";
import { WealthClassification } from "modules/tokenReport/WealthClassification";

export const TopHolderInfo = ({ topHolderData }) => {
  const { topHolderControl, wealthClassification, topHolders } = topHolderData;
  return (
    <Grid container>
      <Grid item md={12} lg={6}>
        <TopHolderControl
          topHolderControl={topHolderControl}
          topHoldersTracked={topHolders.length}
        />{" "}
      </Grid>
      <Grid item md={12} lg={6}>
        <WealthClassification wealthClassification={wealthClassification} />{" "}
      </Grid>
    </Grid>
  );
};
