export const dateFormatter = (dateString) => {
  const year = dateString.slice(0, 4);
  const month = dateString.slice(5, 7) - 1; // Adjusted to slice from index 5 to 7 for the month part
  const day = dateString.slice(8, 10); // Adjusted to slice from index 8 to 10 for the day part

  const parsedDate = new Date(year, month, day);

  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedDate = parsedDate.toLocaleDateString("en-US", options);
  return formattedDate;
};
