/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// @mui material components
import { createTheme } from "@mui/material/styles";

// Vision UI Dashboard React base styles
import colors from "./base/colors";
import breakpoints from "./base/breakpoints";
import typography from "./base/typography";
import boxShadows from "./base/boxShadows";
import borders from "./base/borders";
import globals from "./base/globals";

// Vision UI Dashboard React helper functions
import boxShadow from "./functions/boxShadow";
import hexToRgb from "./functions/hexToRgb";
import linearGradient from "./functions/linearGradient";
import tripleLinearGradient from "./functions/tripleLinearGradient";
import pxToRem from "./functions/pxToRem";
import rgba from "./functions/rgba";

// Vision UI Dashboard React components base styles for @mui material components
import list from "./components/list";
import listItem from "./components/list/listItem";
import listItemText from "./components/list/listItemText";
import card from "./components/card";
import cardMedia from "./components/card/cardMedia";
import cardContent from "./components/card/cardContent";
import switchButton from "./components/form/switchButton";
import tableContainer from "./components/table/tableContainer";
import tableHead from "./components/table/tableHead";
import tableCell from "./components/table/tableCell";
import linearProgress from "./components/linearProgress";
import circularProgress from "./components/circularProgress";
import appBar from "./components/appBar";
import tabs from "./components/tabs";
import tab from "./components/tabs/tab";
import container from "./components/container";
import icon from "./components/icon";
import svgIcon from "./components/svgIcon";
import link from "./components/link";
import { lightMode, darkMode } from "./colorPalette";
import avatar from "./components/avatar";
import wrapper from "./components/wrapper";
import textStyle from "./components/textStyle";

export const makeTheme = (mode) => {
  return createTheme(
    { palette: { mode, ...(mode === "light" ? lightMode : darkMode) } },
    {
      breakpoints: { ...breakpoints },
      typography: { ...typography },
      boxShadows: { ...boxShadows },
      borders: { ...borders },
      functions: {
        boxShadow,
        hexToRgb,
        linearGradient,
        tripleLinearGradient,
        pxToRem,
        rgba,
      },

      components: {
        MuiCssBaseline: {
          styleOverrides: (theme) => {
            return {
              ...globals,
              ...container,
              "a, a:link": {
                textDecoration: "underline",
                // boxShadow: `0 3px 0 ${theme.palette.background.default}`,
              },
              // "apexcharts-radialbar-track": {
              //   color: "black !important",
              //   // boxShadow: `0 3px 0 ${theme.palette.background.default}`,
              // },
              "a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited, a:-webkit-any-link":
                {
                  // color: theme.palette.link.main,
                  // transition: "color 150ms ease-in !important",
                },
              "a:hover": {
                // color: `${theme.palette.link.hover} !important`,
                boxShadow: `none`,

                textDecoration: "underline !important",
              },

              "& .MuiDataGrid-root": {
                border: "none !important",
              },
              "&.MuiDataGrid-columnHeaders": {
                // backgroundColor: theme.palette.brand.accent,
                border: "none !important",
              },
              "& .MuiDataGrid-footerContainer": {
                border: "none !important",
                // backgroundColor: theme.palette.brand.accent,
              },
              ".MuiDataGrid-cell": {
                boxSizing: "none",
                borderBottom: "none !important",
                // backgroundColor: theme.palette.background.default,
              },
              // "& .transfer-type-IN": {
              //   background: `linear-gradient(0deg,${theme.palette.accent.positive} 20%, transparent 100%)`,
              // },
              // "& .transfer-type-OUT": {
              //   background: `linear-gradient(0deg,${theme.palette.accent.negative} 20%, transparent 100%)`,
              //   // backgroundColor: theme.palette.accent.negative,
              // },
            };
          },
        },
        MuiTypography: {
          ...textStyle,
        },
        MuiAvatar: { ...avatar },
        MuiList: { ...list },
        MuiListItem: { ...listItem },
        MuiListItemText: { ...listItemText },
        MuiCard: { ...card },
        MuiCardMedia: { ...cardMedia },
        MuiCardContent: { ...cardContent },
        // MuiSwitch: { ...switchButton },
        MuiTableContainer: { ...tableContainer },
        MuiTableHead: { ...tableHead },
        MuiTableCell: { ...tableCell },
        // MuiLinearProgress: { ...linearProgress },
        MuiCircularProgress: { ...circularProgress },
        MuiAppBar: { ...appBar },
        MuiTabs: { ...tabs },
        MuiTab: { ...tab },
        MuiIcon: { ...icon },
        MuiSvgIcon: { ...svgIcon },
        MuiLink: { ...link },
      },
    }
  );
};
