import * as React from "react";
import { Grid, Card } from "@mui/material";
import { ColumnChart } from "modules/common/charts/column/ColumnChart";
import VuiTypography from "components/VuiTypography";
import { transferCountChartOptions } from "modules/common/charts/column/transferCountConfig";
import { AreaChart } from "modules/common/charts/area/AreaChart";
import { dailyTransfersChartOptions } from "./dailyTransfersChartConfig";

export default function DailyTransfersChart({ data, title, options }) {
  if (data) {
    const sorted = data.sort((a, b) => new Date(a.date) - new Date(b.date));
    const chartData = sorted.map((d) => d.delta);
    const categories = sorted.map((d) => d.date);

    const series = [
      {
        name: title,
        data: chartData,
      },
    ];

    const options = dailyTransfersChartOptions(categories);
    return (
      <Card>
        <Grid container>
          <Grid item xs={12}>
            <VuiTypography variant="componentHeading">{title}</VuiTypography>
            <Card sx={{ height: "350px" }}>
              <AreaChart series={series} options={options} />
            </Card>
          </Grid>
        </Grid>
      </Card>
    );
  } else {
    return <Card sx={{ height: "350px" }}>Data Unavailable</Card>;
  }
}
