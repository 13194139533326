import { dateFormatter } from "./dateFormat";

export const calculateDateRange = (initialDate, interval) => {
  if (interval === 1) {
    const currentDate = new Date(
      initialDate.substring(0, 4),
      initialDate.substring(4, 6) - 1,
      initialDate.substring(6)
    );

    // Calculate the previous day
    const previousDate = new Date(currentDate);
    previousDate.setDate(currentDate.getDate() - 1);

    // Format the previous date in the same format as the input
    const previousDateString = `${previousDate.getFullYear()}${(
      previousDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}${previousDate.getDate().toString().padStart(2, "0")}`;

    // console.log(previousDateString);
    const date = dateFormatter(previousDateString);
    return "Report for " + date;
  }
  // const endDate = new Date(
  //   initialDate.substring(0, 4),
  //   initialDate.substring(4, 6) - 1,
  //   initialDate.substring(6)
  // );
  // console.log("END DATE ", endDate.getDate() - 1);

  // const startDate = new Date(endDate);
  // startDate.setDate(endDate.getDate() - interval);

  // // Format dates as strings in the same format as the input
  // const startDateString = `${startDate.getFullYear()}${(
  //   startDate.getMonth() + 1
  // )
  //   .toString()
  //   .padStart(2, "0")}${startDate.getDate().toString().padStart(2, "0")}`;

  // const endDateString = `${endDate.getFullYear()}${(endDate.getMonth() + 1)
  //   .toString()
  //   .padStart(2, "0")}${endDate.getDate().toString().padStart(2, "0")}`;
  const endDate = new Date(
    initialDate.substring(0, 4),
    initialDate.substring(4, 6) - 1,
    initialDate.substring(6)
  );

  const startDate = new Date(endDate);
  startDate.setDate(endDate.getDate() - interval);

  // Adjust the end date to the day before
  const adjustedEndDate = new Date(endDate);
  adjustedEndDate.setDate(endDate.getDate() - 1);

  // Format dates as strings in the same format as the input
  const startDateString = `${startDate.getFullYear()}${(
    startDate.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}${startDate.getDate().toString().padStart(2, "0")}`;

  const endDateString = `${adjustedEndDate.getFullYear()}${(
    adjustedEndDate.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}${adjustedEndDate.getDate().toString().padStart(2, "0")}`;

  return `Report for ${dateFormatter(startDateString)} to ${dateFormatter(
    endDateString
  )}`;
};
