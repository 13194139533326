export const extractFromDate = (dateString) => {
  if (dateString) {
    const dateObject = new Date(
      Date.UTC(
        parseInt(dateString.substring(0, 4)),
        parseInt(dateString.substring(5, 7)) - 1, // Month is 0-indexed
        parseInt(dateString.substring(8))
      )
    );
    dateObject.setTime(
      dateObject.getTime() + dateObject.getTimezoneOffset() * 60 * 1000
    );

    // Extract the short form of the month
    const monthNameShort = new Intl.DateTimeFormat("en-US", {
      month: "short",
    }).format(dateObject);

    // Extract the day of the month
    const dayNumber = dateObject.getUTCDate();

    const text = monthNameShort + " " + dayNumber;
    // console.log(text, " text - ", dateString);
    // console.log(
    //   dateString,
    //   " : ",
    //   dateObject
    //   // " -- ",
    //   // dayNumber,
    //   // " ----",
    //   // monthNameShort
    // );
    return text;
  }
};
