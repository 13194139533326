import * as React from "react";
import useAxiosV2 from "../../hooks/useAxios";

import { Grid, useTheme } from "@mui/material";
import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { ethers } from "ethers";
import { timeSince } from "utils/timeSince";
import { Logo } from "modules/common/Logo";
import { MyTypography } from "modules/common/MyTypography";
import { DetailCard } from "modules/common/DetailCard";
export const TokenInfo = ({ tokenInfo }) => {
  const theme = useTheme();
  const { response, error, loading } = useAxiosV2({
    axiosInstance: {},
    method: "GET",
    url: `/getTokenInfo/${tokenInfo.contract_address}`,
  });

  if (response) {
    console.log(response, " res");
    let {
      contract_address,
      contract_decimals,
      total_supply,
      contract_name,
      contract_ticker_symbol,
      createdAt,
    } = tokenInfo;
    const { logo_url } = response;
    const lastUpdate = new Date(createdAt);

    let cardData = [
      {
        key: "Contract Address",
        value: contract_address,
      },
      {
        key: "Total Supply",
        value: ethers.utils.commify(total_supply),
      },
      {
        key: "Last Updated",
        value: timeSince(lastUpdate.toISOString()),
      },
    ];

    return (
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <Card>
            <VuiBox>
              <Grid container alignItems="center">
                <Grid container alignItems="center">
                  <Logo src={logo_url} height={25} width={25} />
                  <MyTypography variant="mainHeading">
                    {contract_name} - {contract_ticker_symbol}
                  </MyTypography>
                </Grid>
                <Grid item xs={12}>
                  <VuiBox lineHeight={1}>
                    <Grid container alignItems="center">
                      {cardData.map((data, index) => (
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <DetailCard title={data.key} subtitle={data.value} />
                        </Grid>
                      ))}
                    </Grid>
                  </VuiBox>
                </Grid>
              </Grid>
            </VuiBox>
          </Card>
        </Grid>
      </Grid>
    );
  }
};
