import * as React from "react";
import { Grid, Card, LinearProgress, useTheme, Container } from "@mui/material";
import useAxiosV2 from "../../hooks/useAxios";
import { useParams } from "react-router";
import { MyTypography } from "modules/common/MyTypography";
import { Logo } from "modules/common/Logo";
import { DetailCard } from "modules/common/DetailCard";
import VuiBox from "components/VuiBox";
import { timeSince } from "utils/timeSince";
import { ethers } from "ethers";
import { TopHolderControl } from "./TopHolderControl";
import { ActivityBreakdown } from "./ActivityBreakdown";
import HoldingsDisplay from "./HoldingsDisplay";
import { WealthClassification } from "./WealthClassification";
import { TopHolderV2Context } from "contexts/TopHolderV2Context";
import { WalletProfileContext } from "contexts/WalletProfileContext";
import { TokenContext } from "contexts/TokenContext";
import { dateFormatter } from "utils/dateFormat";
import ActivityList from "./ActivityList";
import ActiveWalletChart from "./ActiveWalletChart";
import { calculateDateRange } from "utils/calculateDateRange";
import TokenVolumeChart from "./TokenVolumeChart";
import TokenActivityChart from "./charts/TokenActivityChart";
import { SimpleTabs } from "modules/common/SimpleTabs";

const createTabs = (topActive, topBuyers, topSellers) => {
  const tabs = [
    {
      label: "Active",
      Component: <ActivityList activityData={topActive} />,
    },

    {
      label: "Receivers",
      Component: <ActivityList activityData={topBuyers} />,
    },
    {
      label: "Senders",
      Component: <ActivityList activityData={topSellers} />,
    },
  ];
  return tabs;
};

export const ActivityReport = ({ stub = false, interval }) => {
  const { tokenAddress, date } = useParams();
  let baseUrl = `/getActivityReport/${tokenAddress}/${interval}`;

  if (date) {
    baseUrl += `/${date}`;
  }

  const { response, error, loading } = useAxiosV2({
    axiosInstance: {},
    method: "GET",
    url: baseUrl,
  });

  if (loading) {
    return <LinearProgress />;
  }
  if (response) {
    const {
      token,
      timestamp,
      chartData,
      topActive,
      topActiveBuyers,
      topActiveSellers,
      topBuyers,
      topSellers,
      totalActiveHolders,
      createdAt,
      interval,
    } = response;
    const active = {
      title: "Top Active Wallets",
      data: topActive
        .sort((a, b) => b.totalTransferCount - a.totalTransferCount)
        .map((x) => {
          const { address, netResult, totalTransferCount } = x;
          return {
            address,
            net: netResult,
            count: totalTransferCount,
          };
        }),
    };
    const senders = {
      title: "Top Senders",
      data: topSellers.map((x) => {
        const { address, netResult, outgoing } = x;
        return {
          address,
          net: netResult,
          count: outgoing.transfers,
        };
      }),
    };
    const receivers = {
      title: "Top Receivers",
      data: topBuyers.map((x) => {
        const { address, netResult, incoming } = x;
        return {
          address,
          net: netResult,
          count: incoming.transfers,
        };
      }),
    };

    const volData = [chartData[0], chartData[1]];

    return (
      // <TokenContext.Provider value={token}>
      //   <TopHolderV2Context.Provider value={context}>
      //     <WalletProfileContext.Provider value={profiles}>
      <Container>
        <h4>{calculateDateRange(createdAt, interval)}</h4>
        <Grid container>
          <Grid item xs={12}>
            <TokenActivityChart chartData={volData} interval={interval} />
          </Grid>
          <SimpleTabs tabs={createTabs(active, receivers, senders)} />

          {/* <Grid item xs={12}>
            <VuiBox lineHeight={1}>
              <ActivityList activityData={active} />
            </VuiBox>
          </Grid>
          <Grid item xs={12}>
            <VuiBox lineHeight={1}>
              <ActivityList activityData={senders} />
            </VuiBox>
          </Grid>
          <Grid item xs={12}>
            <VuiBox lineHeight={1}>
              <ActivityList activityData={receivers} />
            </VuiBox>
          </Grid> */}
        </Grid>
      </Container>
    );
  }
};
