import { ethers, BigNumber } from "ethers";

export const formatWhaleTokenHoldings = (whaleHoldings) => {
  return (whaleHoldings * 100).toFixed(0);
};
export function formatPortfolioValue(value) {
  let portfolioValue = value.toFixed(2);
  const commifiedBalance = ethers.utils.commify(portfolioValue.toString());
  return commifiedBalance;
}
export function formatTokenValue(rawValue, contract_decimals) {
  const rawBalanceString = Number(rawValue).toLocaleString();
  const parsedBalanceString = rawBalanceString.replace(/,/g, "");
  const balanceBN = BigNumber.from(parsedBalanceString);

  const formattedBalance = ethers.utils.formatUnits(
    balanceBN.toString(),
    contract_decimals
  );
  const commifiedBalance = ethers.utils.commify(
    parseInt(formattedBalance).toFixed(2).toString()
  );
  return commifiedBalance;
}

export function formatTokenBalance(balance, contract_decimals) {
  const rawBalanceString = Number(balance).toLocaleString();
  const parsedBalanceString = rawBalanceString.replace(/,/g, "");
  const balanceBN = BigNumber.from(parsedBalanceString);

  const formattedBalance = ethers.utils.formatUnits(
    balanceBN.toString(),
    contract_decimals
  );
  const commifiedBalance = ethers.utils.commify(formattedBalance);
  return commifiedBalance;
}

export const formatAddress = (address) => {
  return (
    address.slice(0, 5) +
    "..." +
    address.slice(address.length - 3, address.length)
  );
};

export const formatChartDate = (value) => {
  const date = new Date(value);

  // Define options for formatting the date
  const options = {
    month: "short", // Use the abbreviated month name (e.g., "Sep" for September)
    day: "numeric", // Use the day of the month
  };
  const formattedDate = date.toLocaleString("en-US", options);
  return formattedDate;
};
