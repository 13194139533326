import React, { Component } from "react";
import Chart from "react-apexcharts";

export const ColumnChart = ({ series, options }) => {
  return (
    <Chart
      options={options}
      series={series}
      type="area"
      width="100%"
      height="100%"
    />
  );
};
