import * as React from "react";
import { Grid, Card, LinearProgress, useTheme, Container } from "@mui/material";
import { MyTypography } from "modules/common/MyTypography";
import VuiBox from "components/VuiBox";

import ActivityList from "./ActivityList";
import ActiveWalletChart from "./ActiveWalletChart";
import DailyTransfersChart from "./DailyTransfersChart";

export const ActivityBreakdown = ({ activityData, context }) => {
  const {
    topActive,
    topActiveBuyers,
    topActiveSellers,
    topBuyers,
    topSellers,
    chartData,
  } = activityData;

  const active = {
    title: "Top Active Wallets",
    data: topActive.map((x) => {
      const { address, netResult, totalTransferCount } = x;
      return {
        address,
        net: netResult,
        count: totalTransferCount,
      };
    }),
  };
  const senders = {
    title: "Top Senders",
    data: topSellers.map((x) => {
      const { address, netResult, outgoing } = x;
      return {
        address,
        net: netResult,
        count: outgoing.transfers,
      };
    }),
  };
  const receivers = {
    title: "Top Receivers",
    data: topBuyers.map((x) => {
      const { address, netResult, incoming } = x;
      return {
        address,
        net: netResult,
        count: incoming.transfers,
      };
    }),
  };

  return (
    <Grid container>
      <Grid item xs={12} md={12} lg={12}>
        {/* <Card> */}
        <VuiBox>
          <Grid container alignItems="center">
            {/* <Grid container alignItems="center">
              <MyTypography variant="mainHeading">
                Activity Breakdown
              </MyTypography>
            </Grid> */}
            <Grid item xs={12}>
              <ActiveWalletChart
                data={chartData.activeWallets}
                title={"Daily Active Wallets"}
              />
            </Grid>
            <Grid item xs={12}>
              <DailyTransfersChart
                data={chartData.dailyTransfers}
                title={"Daily Transfers"}
              />
            </Grid>
            <Grid item xs={12}>
              <VuiBox lineHeight={1}>
                <ActivityList activityData={active} />
              </VuiBox>
            </Grid>
            <Grid item xs={12}>
              <VuiBox lineHeight={1}>
                <ActivityList activityData={senders} />
              </VuiBox>
            </Grid>
            <Grid item xs={12}>
              <VuiBox lineHeight={1}>
                <ActivityList activityData={receivers} />
              </VuiBox>
            </Grid>
            {/* <Grid item xs={12}>
                <VuiBox lineHeight={1}>
                  <ActivityDisplay activity={active} context={context} />
                </VuiBox>
              </Grid> */}
            {/* <Grid item xs={12}>
                <VuiBox lineHeight={1}>
                  <ActivityDisplay activity={senders} context={context} />
                </VuiBox>
              </Grid>
              <Grid item xs={12}>
                <VuiBox lineHeight={1}>
                  <ActivityDisplay activity={receivers} context={context} />
                </VuiBox>
              </Grid> */}
          </Grid>
        </VuiBox>
        {/* </Card> */}
      </Grid>
    </Grid>
  );
};
