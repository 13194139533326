import axios from "axios";
import { BASE_URL } from "./config";
// import https from "https";

export const axiosV2 = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  // httpsAgent: new https.Agent({
  //   rejectUnauthorized: false,
  // }),
});
