import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";

import { Grid, Card, IconButton } from "@mui/material";
import { TopHolderV2Context } from "contexts/TopHolderV2Context";
import { WalletProfileContext } from "contexts/WalletProfileContext";
import { ethers } from "ethers";
import { EtherscanLink } from "modules/common/EtherscanLink";
import { RankIcon } from "modules/common/RankIcon";
import { getTopHolderRank } from "utils/getTopHolderRank";
import { WealthIcon } from "modules/common/WealthIcon";
import { TokenContext } from "contexts/TokenContext";
import { useContext } from "react";
import { timeSince } from "utils/timeSince";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { TopHoldersContext } from "contexts/TopHoldersContext";
export default function AssetHolderList({ holders }) {
  const thContext = useContext(TopHoldersContext);
  const walletContext = useContext(WalletProfileContext);
  const tokenContext = useContext(TokenContext);
  holders.sort((a, b) => b.balance - a.balance);
  // const { title, data } = activityData;
  // const thContext = useContext(TopHolderV2Context);
  // const walletContext = useContext(WalletProfileContext);
  // const tokenContext = useContext(TokenContext);
  // const { contract_ticker_symbol } = tokenContext;
  return (
    <Card>
      <List>
        <h3>Holders</h3>
        <Grid container>
          {holders.map((holder) => {
            // const rank = getTopHolderRank(thContext, holder.address);
            // const wallet = walletContext[d.address]
            //   ? walletContext[d.address]
            //   : false;
            const { address, balance, last_transferred_at, quote } = holder;
            const rank = getTopHolderRank(thContext, address);

            const wallet = walletContext[address];
            const { name, assetCount, portfolioValue, wealthCategory } = wallet;
            return (
              <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={12} lg={6}>
                  <ListItem style={{ marginBottom: "10px", marginTop: "10px" }}>
                    <ListItemAvatar>
                      <RankIcon rank={rank} />
                    </ListItemAvatar>
                    <ListItemAvatar>
                      <WealthIcon name={wealthCategory[0].category} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <EtherscanLink
                          path={`address/${address}`}
                          name={name ? name : address}
                        />
                      }
                      secondary={`${
                        wallet
                          ? `$${ethers.utils.commify(
                              portfolioValue.toFixed()
                            )} (${assetCount} assets)`
                          : "No wallet found."
                      }`}
                    />
                  </ListItem>
                </Grid>
                <Grid item xs={6}>
                  <ListItem>
                    <ListItemText
                      primary={`${ethers.utils.commify(balance.toFixed())} ${
                        tokenContext.contract_ticker_symbol
                      }`}
                      secondary={`${
                        quote
                          ? `$${ethers.utils.commify(quote.toFixed(2))} - `
                          : ``
                      } Last Seen ${timeSince(last_transferred_at)}`}
                    />
                  </ListItem>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </List>
    </Card>
  );
}
