import * as React from "react";
import {
  Grid,
  Box,
  Card,
  Stack,
  LinearProgress,
  Container,
} from "@mui/material";
import useAxiosV2 from "hooks/useAxios";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { TokenContainer } from "modules/token/TokenContainer";

export const Home = () => {
  const { response, error, loading } = useAxiosV2({
    axiosInstance: {},
    method: "GET",
    url: `/getTokens`,
  });

  if (loading) {
    return <LinearProgress />;
  }
  if (response) {
    return (
      <Container>
        <Card>
          <VuiBox sx={{ width: "100%" }}>
            <Card>
              <VuiBox
                display="flex"
                alignItems="center"
                justifyContent="space-beetween"
                sx={{ width: "100%" }}
                // mb="40px"
              >
                <VuiTypography variant="masterHeading">
                  Welcome to KryptoMatrix
                </VuiTypography>
              </VuiBox>
              <VuiBox
                display="flex"
                alignItems="center"
                justifyContent="space-beetween"
                sx={{ width: "100%" }}
                mb="10px"
              >
                <VuiTypography variant="subtext">
                  Top Holder Insights at Your Fingertips: Explore Whale Wallets,
                  Activity, and Common Asset connections
                </VuiTypography>
              </VuiBox>
            </Card>

            <TokenContainer tokens={response} />
          </VuiBox>
        </Card>
      </Container>
    );
  }
};
