import * as React from "react";
import { Grid, Card, useTheme } from "@mui/material";
import VuiTypography from "components/VuiTypography";
import { RadialBarChart } from "modules/common/charts/radialBar/RadialBarChart";
import { createRadialChartOptions } from "modules/common/charts/radialBar/chartConfig";
import VuiBox from "components/VuiBox";
import { WealthIcon } from "modules/common/WealthIcon";

export const WealthClassification = ({ wealthClassification }) => {
  const theme = useTheme();
  // const wealthCategories = [
  //   { category: "Squid", min: 0, max: 50000 },
  //   { category: "Fish", min: 50000, max: 250000 },
  //   { category: "Dolphin", min: 250000, max: 5000000 },
  //   { category: "Shark", min: 5000000, max: 25000000 },
  //   { category: "Whale", min: 25000000, max: Infinity },
  // ];
  const valueGroups = [0, 49999, 249999, 4999999, 24999999];
  const labels = ["$0-$50k", "$50k-$250k", "$250k-$5m", "$5m-$25m ", "$25m+"];
  const dataRadial = valueGroups.map((group, index) => {
    const nextGroup = valueGroups[index + 1];
    const wealth = wealthClassification.filter(
      (wealthClass) =>
        wealthClass.average >= group &&
        (nextGroup ? wealthClass.average < nextGroup : true)
    );
    return wealth.length ? wealth[0].count : 0;
  });

  const radialOptions = createRadialChartOptions(labels, theme);

  return (
    <Card>
      <VuiTypography variant="componentHeading">Wealth Class</VuiTypography>
      <Grid container>
        <Grid item xs={12}>
          <Card sx={{ height: "250px" }}>
            <RadialBarChart series={dataRadial} options={radialOptions} />
          </Card>
        </Grid>
        {wealthClassification.map((type) => {
          const { count, category } = type;
          return (
            <Grid item xs={6} sm={4} md={3} lg={3}>
              <Card>
                <Grid container alignItems="center">
                  <Grid item xs={12}>
                    <VuiBox lineHeight={1}>
                      <VuiTypography variant="subtext">
                        {category} ({count})
                      </VuiTypography>
                    </VuiBox>
                    <WealthIcon name={category} />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Card>
  );
};
