import {
  faDiscord,
  faTwitter,
  faMedium,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Link, useTheme } from "@mui/material";
import VuiTypography from "components/VuiTypography";
import * as React from "react";

const Footer = () => {
  const theme = useTheme();
  return (
    <div style={{ left: 0, bottom: 0, right: 0, position: "relative" }}>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        bgcolor={theme.palette.primary.main}
        style={{ height: "100px" }}
      >
        <Grid item>
          <VuiTypography variant="body1" color="white">
            Follow us
          </VuiTypography>
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            {/* <Link href={"https://discord.gg/2A4k5FnV"} target="_blank">
              <FontAwesomeIcon color="white" icon={faDiscord} />{" "}
            </Link> */}
            <Link href={"https://twitter.com/kryptomatrixHQ"} target="_blank">
              <FontAwesomeIcon color="white" icon={faXTwitter} />{" "}
            </Link>
            <Link href={"https://medium.com/@kryptomatrix"} target="_blank">
              <FontAwesomeIcon color="white" icon={faMedium} />{" "}
            </Link>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
