import * as React from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";

function CollapsibleRow(props) {
  const { row, index, CollapsibleComponent } = props;
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {row}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {CollapsibleComponent}{" "}
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
const createCols = (columns) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell></TableCell>
        {Object.entries(columns).map(([key, value]) => {
          return <TableCell>{value}</TableCell>;
        })}
      </TableRow>
    </TableHead>
  );
};
export default function CollapsibleTable({ cols, rowData }) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Table aria-label="collapsible table">
          {createCols(cols)}
          <TableBody>
            {rowData.map((data, index) => (
              <CollapsibleRow
                // key={topHolder.address}
                row={data.row}
                CollapsibleComponent={data.CollapsibleComponent}
                index={index}
              />
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
}
