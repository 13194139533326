import React, { useState } from "react";

export const GlobalContext = React.createContext({
  themeMode: "",
  themeToggleHandler: () => {},
});

const GlobalContextProvider = (props) => {
  const [themeMode, setThemeMode] = useState(
    window.localStorage.getItem("theme") == null
      ? "light"
      : window.localStorage.getItem("theme")
  );

  const themeToggleHandler = (themeMode) => {
    console.log("setting themeMode to " + themeMode);
    setThemeMode(themeMode);
  };

  return (
    <GlobalContext.Provider
      value={{
        themeMode: themeMode,
        themeToggleHandler: themeToggleHandler,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
