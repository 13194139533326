import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";

import { Grid, Card, IconButton } from "@mui/material";

import { ethers } from "ethers";
import { EtherscanLink } from "modules/common/EtherscanLink";
import { RankIcon } from "modules/common/RankIcon";
import { WealthIcon } from "modules/common/WealthIcon";
import { TokenContext } from "contexts/TokenContext";
import { useContext } from "react";
import { timeSince } from "utils/timeSince";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { WalletProfileContext } from "contexts/WalletProfileContext";

export function WalletProfileStub({ address, index }) {
  const walletContext = useContext(WalletProfileContext);
  const walletProfile = walletContext[address];

  if (walletProfile) {
    return (
      <>
        <ListItem style={{ marginBottom: "10px", marginTop: "10px" }}>
          <ListItemAvatar>
            <RankIcon rank={index >= 0 ? index : "?"} />
          </ListItemAvatar>
          <ListItemAvatar>
            <WealthIcon
              name={
                walletProfile.wealthCategory
                  ? walletProfile.wealthCategory[0].category
                  : "None Found"
              }
            />
          </ListItemAvatar>
          <ListItemText
            primary={
              <EtherscanLink
                path={`address/${address}`}
                name={walletProfile.name ? walletProfile.name : address}
              />
            }
            secondary={`${
              walletProfile
                ? `$${ethers.utils.commify(
                    walletProfile.portfolioValue.toFixed()
                  )} (${walletProfile.assetCount} assets)`
                : "No wallet found."
            } ${walletProfile.tag ? ` - ${walletProfile.tag}` : ``}`}
          />
        </ListItem>
      </>
    );
  } else {
    return (
      <>
        <ListItem style={{ marginBottom: "10px", marginTop: "10px" }}>
          <ListItemAvatar>
            <RankIcon rank={index >= 0 ? index : "?"} />
          </ListItemAvatar>
          <ListItemAvatar>
            <WealthIcon name={null} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <EtherscanLink path={`address/${address}`} name={address} />
            }
            secondary={"No wallet found."}
          />
        </ListItem>
      </>
    );
  }

  // return (
  //   <>
  //     <ListItem style={{ marginBottom: "10px", marginTop: "10px" }}>
  //       <ListItemAvatar>
  //         <RankIcon rank={index >= 0 ? index : "?"} />
  //       </ListItemAvatar>
  //       <ListItemAvatar>
  //         <WealthIcon name={wealthCategory[0].category} />
  //       </ListItemAvatar>
  //       <ListItemText
  //         primary={
  //           <EtherscanLink
  //             path={`address/${address}`}
  //             name={walletProfile.name ? walletProfile.name : address}
  //           />
  //         }
  //         secondary={`${
  //           walletProfile
  //             ? `$${ethers.utils.commify(
  //                 portfolioValue.toFixed()
  //               )} (${assetCount} assets)`
  //             : "No wallet found."
  //         } ${tag ? ` - ${tag}` : ``}`}
  //       />
  //     </ListItem>
  //   </>
  // );
}
