import * as React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

function Row(props) {
  const { row } = props;
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>{row}</TableRow>
    </React.Fragment>
  );
}
const createCols = (columns) => {
  return (
    <TableHead>
      <TableRow>
        {Object.entries(columns).map(([key, value]) => {
          return <TableCell>{value}</TableCell>;
        })}
      </TableRow>
    </TableHead>
  );
};
export default function TableContainer({ cols, rowData }) {
  return (
    <Table aria-label="collapsible table">
      {createCols(cols)}
      <TableBody>
        {rowData.map((data, index) => {
          return <Row key={index} row={data} index={index} />;
        })}
      </TableBody>
    </Table>
  );
}
