import * as React from "react";
import { Grid, Tab, Tabs, Typography, Box } from "@mui/material";
import VuiBox from "components/VuiBox";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <VuiBox sx={{ width: "100%" }}>{children}</VuiBox>}
    </div>
  );
}

export const SimpleTabs = ({ tabs }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <VuiBox sx={{ width: "100%" }}>
      <VuiBox
        display="flex"
        alignItems="center"
        justifyContent="space-beetween"
        sx={{ width: "100%" }}
        mb="40px"
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {tabs.map(({ label }, i) => (
            <Tab label={label} key={i} />
          ))}
        </Tabs>
      </VuiBox>
      {tabs.map(({ Component }, i) => (
        <TabPanel value={value} index={i} key={i}>
          {Component}
        </TabPanel>
      ))}
    </VuiBox>
  );
};
