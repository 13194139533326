import { Routes, Route } from "react-router-dom";
import { Home } from "modules/Home";
import { TokenReport } from "modules/tokenReport/TokenReport";
import { TokenDashboardV2 } from "modules/topHoldersV2/TokenDashboardV2";
export const AppRouter = () => {
  return (
    <Routes>
      <Route path="" element={<Home />} />
      <Route path="report/:tokenAddress" element={<TokenReport />} />
      <Route path="report/:tokenAddress/:date" element={<TokenReport />} />
      <Route
        path="tokenV2/:tokenAddress"
        exact
        element={<TokenDashboardV2 />}
      />
      <Route path="*" element={<p>Not found!</p>} />
    </Routes>
  );
};
