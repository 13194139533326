import * as React from "react";
import { Grid, Card, useTheme } from "@mui/material";
import VuiTypography from "components/VuiTypography";
import { RadialBarChart } from "modules/common/charts/radialBar/RadialBarChart";
import { createRadialChartOptions } from "modules/common/charts/radialBar/chartConfig";
import VuiBox from "components/VuiBox";
import { WealthIcon } from "modules/common/WealthIcon";
import Chart from "react-apexcharts";
import { ethers } from "ethers";

export const TopHoldersBreakdownChart = ({ topHolders, totalSupply }) => {
  //   const chartData = topHolders.map((x) => {
  //     return {
  //       name: x.address,
  //       data: x.balance,
  //     };
  //   });
  const chartData = topHolders.map((x) => {
    return {
      address: x.address,
      balance: x.balance,
    };
  });

  const series = [];
  const categories = [];
  chartData.map((d) => {
    series.push(d.balance);
    categories.push(d.address);
  });

  const options = {
    chart: {
      type: "donut",
    },
    dataLabels: {
      enabled: true,
      formatter: (value, opts) => {
        const dataValue = opts.w.globals.series[opts.seriesIndex];
        return `${((dataValue / totalSupply) * 100).toFixed(2)}%`;
      },
    },
    labels: categories,
    tooltip: {
      enabled: true,
      y: {
        formatter: (value) => {
          //   console.log(value, " value");
          return ethers.utils.commify(value.toFixed(2));
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <>
      <Card sx={{ height: "500px" }}>
        <VuiTypography variant="componentHeading">
          Holdings Breakdown
        </VuiTypography>
        <Chart
          options={options}
          series={series}
          type="donut"
          width="100%"
          height="95%"
        />
      </Card>
    </>
  );
};
