import * as React from "react";

import CollapsibleTable from "./CollapsibleTable";
import { Grid } from "@mui/material";
import VuiTypography from "components/VuiTypography";

export default function CollapsibleContainer({ columns, rowData, heading }) {
  return (
    <Grid container>
      <Grid item xs={12}>
        {/* <h1>{heading}</h1> */}
        {/* <VuiTypography
          variant="subtitle"
          // color={bgColor === "white" ? "text" : "white"}
          textTransform="capitalize"
          fontWeight="medium"
        >
          {heading}
        </VuiTypography> */}
        <CollapsibleTable cols={columns} rowData={rowData} />
      </Grid>
    </Grid>
  );
}
