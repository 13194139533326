import * as React from "react";
import { Grid, Card, LinearProgress, useTheme, Container } from "@mui/material";
import useAxiosV2 from "../../hooks/useAxios";
import { useParams } from "react-router";
import { MyTypography } from "modules/common/MyTypography";
import { Logo } from "modules/common/Logo";
import { DetailCard } from "modules/common/DetailCard";
import VuiBox from "components/VuiBox";
import { timeSince } from "utils/timeSince";
import { ethers } from "ethers";
import { TopHolderControl } from "./TopHolderControl";
import { ActivityBreakdown } from "./ActivityBreakdown";
import HoldingsDisplay from "./HoldingsDisplay";
import { WealthClassification } from "./WealthClassification";
import { TopHolderV2Context } from "contexts/TopHolderV2Context";
import { WalletProfileContext } from "contexts/WalletProfileContext";
import { TokenContext } from "contexts/TokenContext";
import { dateFormatter } from "utils/dateFormat";

export const TokenReport = ({ stub = false }) => {
  const { tokenAddress, date } = useParams();

  let baseUrl = `/getTokenReport/${tokenAddress}`;

  if (date) {
    baseUrl += `/${date}`;
  }

  const { response, error, loading } = useAxiosV2({
    axiosInstance: {},
    method: "GET",
    url: baseUrl,
  });

  if (loading) {
    return <LinearProgress />;
  }
  if (response && response.success) {
    const {
      token,
      timestamp,
      topHolderControl,
      chartData,
      topActive,
      topActiveBuyers,
      topActiveSellers,
      topBuyers,
      topSellers,
      commonHoldings,
      uniqueHoldings,
      wealthClassification,
      profiles,
      context,
      createdAt,
    } = response;
    const {
      totalSupply,
      contract_name,
      contract_decimals,
      contract_ticker_symbol,
      logo_url,
      contract_address,
      topHoldersTracked,
    } = token;
    let cardData = [
      {
        key: "Contract Address",
        value: contract_address,
      },
      {
        key: "Total Supply",
        value: ethers.utils.commify(totalSupply),
      },
      {
        key: "Last Updated",
        value: timeSince(timestamp),
      },
    ];

    const activityData = {
      topActive,
      topActiveBuyers,
      topActiveSellers,
      topBuyers,
      topSellers,
      chartData,
    };
    return (
      <TokenContext.Provider value={token}>
        <TopHolderV2Context.Provider value={context}>
          <WalletProfileContext.Provider value={profiles}>
            <Container>
              {stub ? null : (
                <Grid container>
                  <Grid item xs={12} md={12} lg={12}>
                    <Card>
                      <VuiBox>
                        <Grid container alignItems="center">
                          <Grid container alignItems="center">
                            <Logo src={logo_url} height={50} width={50} />
                            <MyTypography variant="mainHeading">
                              {contract_name} - {contract_ticker_symbol}
                            </MyTypography>
                          </Grid>
                          <Grid item xs={12}>
                            <VuiBox lineHeight={1}>
                              <Grid container alignItems="center">
                                {cardData.map((data, index) => (
                                  <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <DetailCard
                                      title={data.key}
                                      subtitle={data.value}
                                    />
                                  </Grid>
                                ))}
                              </Grid>
                            </VuiBox>
                          </Grid>
                        </Grid>
                      </VuiBox>
                    </Card>
                  </Grid>
                </Grid>
              )}
              <h1>{dateFormatter(createdAt)}</h1>
              <Grid container>
                <Grid item xs={12}>
                  <ActivityBreakdown
                    activityData={activityData}
                    context={response.context}
                  />
                </Grid>
                <Grid item xs={12}>
                  <HoldingsDisplay
                    holdings={commonHoldings}
                    title={"Common Assets"}
                  />
                </Grid>
                <Grid item xs={12}>
                  <HoldingsDisplay
                    holdings={uniqueHoldings}
                    title={"Unique Assets"}
                  />
                </Grid>
              </Grid>
            </Container>
          </WalletProfileContext.Provider>
        </TopHolderV2Context.Provider>
      </TokenContext.Provider>
    );
  }
};
