import * as React from "react";
import { Grid, Card } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { Logo } from "modules/common/Logo";
import { EtherscanLink } from "modules/common/EtherscanLink";
import CollapsibleContainer from "modules/common/tables/CollapsibleContainer";
import { MyTypography } from "modules/common/MyTypography";
import AssetHolderList from "./AssetHolderList";
import { TokenContext } from "contexts/TokenContext";

const commonAssetCols = {
  name: "Name",
  // totalValue: "Total Value (USD)",
  holders: "Holders",
};

function createRowData(dataset) {
  let rowData = dataset.map((data) => {
    let row = (
      <>
        <TableCell component="th" scope="row">
          <Logo src={data.logo_url} />
          <EtherscanLink
            path={`address/${data.contract_address}`}
            name={`${data.contract_ticker_symbol} - ${data.contract_name}`}
          />
        </TableCell>
        {/* <TableCell>{data.totalHoldingsValue.toLocaleString()}</TableCell> */}
        <TableCell>{data.holders.length}</TableCell>
      </>
    );
    const CollapsibleComponent = (
      <TokenContext.Provider value={data}>
        <AssetHolderList holders={data.holders} />
      </TokenContext.Provider>
    );

    return {
      row,
      CollapsibleComponent,
    };
  });

  return rowData;
}
export default function HoldingsDisplay({ holdings, title }) {
  // console.log(report, " report 2222");
  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <MyTypography variant="mainHeading">{title}</MyTypography>

          <CollapsibleContainer
            columns={commonAssetCols}
            rowData={createRowData(holdings)}
            heading={title}
          />
        </Card>
      </Grid>
    </Grid>
  );
}
