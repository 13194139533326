export const extractTimeFromDate = (dateString) => {
  if (!dateString) {
    return null;
  }

  const parts = dateString.split(" ");

  // If the split produces two parts (date and time), return the time part
  if (parts.length === 2) {
    const timePart = parts[1];
    const timeParts = timePart.split(":").slice(0, 2); // Take only hours and minutes
    const formattedTime = timeParts.join(":");
    // console.log("returning ", formattedTime);
    return formattedTime;
  }

  // If the format is not as expected, return null or handle it as needed
  return null;
};
