/* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Vision UI Dashboard React base styles
import borders from "../base/borders";
import colors from "../base/colors";

// Vision UI Dashboard React helper functions
import pxToRem from "../functions/pxToRem";

const { borderRadius } = borders;
const { light } = colors;

export default {
  styleOverrides: {
    root: ({ theme }) => ({
      height: pxToRem(3),
      borderRadius: borderRadius.md,
      overflow: "visible",
      position: "relative",
      // backgroundColor: theme.palette.accent.primary,
    }),
    // colorPrimary: {
    //   backgroundColor: "yellow",
    // },

    // colorSecondary: {
    //   backgroundColor: light.main,
    // },

    bar: ({ theme }) => ({
      height: pxToRem(6),
      borderRadius: borderRadius.sm,
      position: "absolute",
      transform: `translate(0, ${pxToRem(-1.5)}) !important`,
      transition: "width 10s ease !important",
      // backgroundColor: theme.palette.accent.primary,
    }),
  },
};
