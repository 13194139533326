import { Logo } from "modules/common/Logo";
import { Grid, Card, TableCell } from "@mui/material";
import TableContainer from "modules/common/tables/TableContainer";
import { KMLink } from "modules/common/KMLink";
const columns = {
  name: "Token",
  // whalePercentage: "Top Holder Control",
  // total_supply: "Top Holder Rating",
};

function createRowData(tokens) {
  let rowData = tokens.map((token) => {
    const {
      logo_url,
      symbol,
      name,
      contract_address,
      // topHolderRating,
      // topHolderPercentage,
      // ratings,
    } = token;

    let row = (
      <>
        <TableCell component="th" scope="row">
          <Logo src={logo_url} />
          <KMLink
            path={`tokenV2/${contract_address}`}
            name={`${symbol} - ${name}`}
            local
          />
          {/* <a href={`token/${contract_address}`}>
            {contract_ticker_symbol} - {contract_name}
          </a> */}
        </TableCell>
        {/* <TableCell>{topHolderPercentage.toFixed(2)}%</TableCell>
        <TableCell>
          <WealthIcon name={rating.category} />
        </TableCell> */}
      </>
    );

    return row;
  });

  return rowData;
}

export function TokenContainer({ tokens }) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <TableContainer cols={columns} rowData={createRowData(tokens)} />
        </Card>
      </Grid>
    </Grid>
  );
}
