import { ethers } from "ethers";

export const dailyTransfersChartOptions = (categories) => {
  return {
    chart: {
      type: "area",
      height: 350,
    },
    plotOptions: {
      bar: {
        borderRadius: 7,
        columnWidth: "45%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: categories,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      // labels: {
      //   formatter: function (value) {
      //     return (
      //       value.slice(0, 5) +
      //       "..." +
      //       value.slice(value.length - 3, value.length)
      //     );
      //   },
      // },
    },
    yaxis: {
      title: {
        text: "Transfers",
      },
      labels: {
        formatter: function (value) {
          return ethers.utils.commify(value);
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return ethers.utils.commify(value) + " transfers";
        },
      },
    },
  };
};
