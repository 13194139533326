import { axiosV2 } from "api/axiosController";
import axios from "axios";
import { useEffect, useState } from "react";
import { BASE_URL, V2_BASE_URL } from "../api/config";

const useAxios = (config) => {
  const { axiosInstance, method, url, requestConfig = {} } = config;

  const [response, setResponse] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await axios.get(BASE_URL + url, {
          signal: controller.signal,
        });
        // console.log("RETURNING RESPONSE");
        setResponse(res.data);
      } catch (e) {
        console.log(e, "ERROR");
        setError(e);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // console.log(response, " returning response");

    return () => controller.abort();
  }, []);
  return { response, error, loading };
};

const useAxiosV2 = (config) => {
  const { axiosInstance, method, url, requestConfig = {} } = config;
  // console.log("Using axios ... ", V2_BASE_URL);
  const [response, setResponse] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      try {
        setLoading(true);
        // console.log(`Sending request to ::: ${V2_BASE_URL + url}`);
        // console.log(axiosV2, "instance");
        // const res = axiosV2.get(url, {
        //   signal: controller.signal,
        // });

        const res = await axios.get(V2_BASE_URL + url, {
          signal: controller.signal,
          // httpsAgent: new https.Agent({
          //   rejectUnauthorized: false,
          // }),
        });
        setResponse(res.data);
      } catch (e) {
        console.log(e, "ERROR");
        setError(e);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    return () => controller.abort();
  }, []);
  return { response, error, loading };
};

export default (useAxios, useAxiosV2);
