import * as React from "react";
import Chart from "react-apexcharts";
import { Grid, Card } from "@mui/material";
import VuiTypography from "components/VuiTypography";
import { activeWalletChartOptions } from "./activeWalletChartConfig";
import { extractFromDate } from "utils/extractFromDate";
import { TokenContext } from "contexts/TokenContext";
import { tokenVolumeChartOptions } from "./tokenVolumeChartConfig";

export default function TokenActivityChart({ chartData, interval }) {
  const tokenInfo = React.useContext(TokenContext);

  if (chartData) {
    // console.log(chartData, " token vol data");
    const vol = chartData[1];
    const activeWallets = chartData[0];

    const { data: volData, title: volTitle } = vol;
    const { data: walletData, title: walletTitle } = activeWallets;

    const sortedVol = volData.sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    );
    const sortedWallets = walletData.sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    );
    const volCategories = sortedVol.map((d) => d.date);
    const walletCategories = sortedWallets.map((d) => extractFromDate(d.date));
    const incoming = sortedVol.map((d) => d.incoming);
    const outgoing = sortedVol.map((d) => d.outgoing);
    const wallets = sortedWallets.map((d) => d.delta);
    const volSeries = [
      {
        name: "Incoming",
        data: incoming,
      },
      {
        name: "Outgoing",
        data: outgoing,
      },
    ];
    const walletSeries = [
      {
        name: "Active Wallets",
        data: wallets,
      },
    ];
    // console.log(wallets, " wallets");
    const volOptions = tokenVolumeChartOptions(
      volCategories,
      volTitle,
      interval,
      tokenInfo.contract_ticker_symbol
    );
    const walletOptions = activeWalletChartOptions(
      walletCategories,
      walletTitle,
      interval,
      "wallets"
    );
    return (
      <Card>
        <Grid container>
          <Grid item xs={12}>
            <VuiTypography variant="componentHeading">Activity</VuiTypography>
            {/* <Card sx={{ height: "350px" }}> */}
            <Chart
              options={volOptions}
              series={volSeries}
              type="area"
              width="100%"
              height={350}
            />
            <Chart
              options={walletOptions}
              series={walletSeries}
              type="area"
              width="100%"
              height={150}
            />
          </Grid>
        </Grid>
      </Card>
    );
  } else {
    return <Card sx={{ height: "350px" }}>Data Unavailable</Card>;
  }
}
