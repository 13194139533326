import { Avatar } from "@mui/material";
import VuiTypography from "components/VuiTypography";

export const RankIcon = ({ rank }) => {
  return (
    <Avatar>
      {/* <Avatar sx={{ color: "black" }}> */}
      {rank}
    </Avatar>
  );
};
