export const Logo = ({ src, height = 25, width = 25 }) => {
  return src ? (
    <img
      src={src}
      width={width}
      height={height}
      style={{
        borderRadius: "100%",
        marginRight: "10px",
        verticalAlign: "middle",
      }}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = "/static/question.png";
      }}
    />
  ) : (
    <img
      src={"/static/question.png"}
      width={width}
      height={height}
      style={{
        borderRadius: "100%",
        marginRight: "10px",
        verticalAlign: "middle",
      }}
    />
  );
};
