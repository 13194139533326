import * as React from "react";
import { Grid, Card } from "@mui/material";
import { ColumnChart } from "modules/common/charts/column/ColumnChart";
import VuiTypography from "components/VuiTypography";
import { transferCountChartOptions } from "modules/common/charts/column/transferCountConfig";
import { AreaChart } from "modules/common/charts/area/AreaChart";
import { activeWalletChartOptions } from "./charts/activeWalletChartConfig";
import { extractTimeFromDate } from "utils/extractTimeFromDate";
import { extractFromDate } from "utils/extractFromDate";

export default function TokenVolumeChart({ data, title, interval }) {
  if (data) {
    const sorted = data.sort((a, b) => new Date(a.date) - new Date(b.date));
    // const chartData = sorted.map((d) => d.delta);
    const categories = sorted.map((d) => d.date);
    // console.log(categories, " categories");

    let formattedCategories = [];

    if (interval === 1) {
      const temp = categories.map((c) => extractTimeFromDate(c));
      formattedCategories.push(...temp);
    } else {
      const temp = categories.map((c) => extractFromDate(c));
      formattedCategories.push(...temp);
    }

    const incoming = data.map((d) => d.incoming);
    const outgoing = data.map((d) => d.outgoing);
    const series = [
      {
        name: "Incoming",
        data: incoming,
      },
      {
        name: "Outgoing",
        data: outgoing,
      },
    ];
    const options = activeWalletChartOptions(
      formattedCategories,
      title,
      interval
    );
    return (
      <Card>
        <Grid container>
          <Grid item xs={12}>
            <VuiTypography variant="componentHeading">{title}</VuiTypography>
            <Card>
              <AreaChart series={series} options={options} />
            </Card>
          </Grid>
        </Grid>
      </Card>
    );
  } else {
    return <Card sx={{ height: "350px" }}>Data Unavailable</Card>;
  }
}
