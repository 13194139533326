import React, { Component } from "react";
import Chart from "react-apexcharts";

export const AreaChart = ({ series, options }) => {
  // console.log(series, " series");
  // console.log(options, " options");
  return (
    <Chart
      options={options}
      series={series}
      type="area"
      width="100%"
      height="100%"
      // height={height}
    />
  );
};
