import { ethers } from "ethers";
import { dateFormatter } from "utils/dateFormat";
import { extractFromDate } from "utils/extractFromDate";
import { extractTimeFromDate } from "utils/extractTimeFromDate";

export const activeWalletChartOptions = (
  categories,
  title,
  interval,
  yText
) => {
  return {
    chart: {
      type: "area",
      toolbar: {
        show: false,
      },
      // offsetX: 50moffsetX: 50
      // height: 350,
    },
    grid: {
      show: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 7,
        columnWidth: "45%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      // colors: ["transparent"],
    },
    xaxis: {
      categories: categories,
      axisBorder: {
        show: false,
      },
      tickAmount: interval >= 7 ? 6 : 8,
      axisTicks: {
        show: false,
      },
      // labels: {
      //   formatter: function (value) {
      //     const date = dateFormatter(value);
      //     console.log(date, " date ");
      //     return date;
      //   },
      // },
    },
    yaxis: {
      type: "logarithmic", // Apply logarithmic scaling to the y-axis
      logarithmBase: 10,
      title: {
        text: title,
      },
      labels: {
        minWidth: 50,
        formatter: function (value) {
          // console.log(value.w, " label value");
          return ethers.utils.commify(value);
        },
      },
    },
    fill: {
      opacity: 1,
    },
    // colors: ["#008FFB", "#FF4560"],
    colors: ["#008FFB", "#FF4560"],

    tooltip: {
      y: {
        formatter: function (value) {
          return ethers.utils.commify(value.toFixed()) + " " + yText;
        },
      },
    },
  };
};
