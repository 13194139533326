import React, { useContext } from "react";

import { ThemeProvider } from "@mui/material/styles";
// import "./App.css"; // Import the CSS file for styling

import { CssBaseline } from "@mui/material";
import { GlobalContext } from "contexts/GlobalContext";
import { makeTheme } from "style/theme";
import Footer from "Footer";

const App = ({ children }) => {
  const { themeMode } = useContext(GlobalContext);
  let theme = makeTheme(themeMode);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div style={{ minHeight: "90vh" }}>
        <main>{children}</main>
      </div>

      <Footer />
    </ThemeProvider>
  );
};

export default App;
