import * as React from "react";
import { Grid, Card, LinearProgress, useTheme, Container } from "@mui/material";
import useAxiosV2 from "../../hooks/useAxios";
import { useParams } from "react-router";
import { MyTypography } from "modules/common/MyTypography";
import { Logo } from "modules/common/Logo";
import { DetailCard } from "modules/common/DetailCard";
import VuiBox from "components/VuiBox";
import { timeSince } from "utils/timeSince";
import { ethers } from "ethers";
import { TopHolderControl } from "./TopHolderControl";
import { ActivityBreakdown } from "./ActivityBreakdown";
import HoldingsDisplay from "./HoldingsDisplay";
import { WealthClassification } from "./WealthClassification";
import { TopHolderV2Context } from "contexts/TopHolderV2Context";
import { WalletProfileContext } from "contexts/WalletProfileContext";
import { TokenContext } from "contexts/TokenContext";
import { dateFormatter } from "utils/dateFormat";

export const HoldingsReport = ({ tokenAddress }) => {
  // const { tokenAddress, date } = useParams();

  let baseUrl = `/getHoldingsReport/${tokenAddress}`;

  const { response, error, loading } = useAxiosV2({
    axiosInstance: {},
    method: "GET",
    url: baseUrl,
  });

  if (loading) {
    return <LinearProgress />;
  }
  if (response) {
    const { commonHoldings, uniqueHoldings } = response;

    return (
      // <TokenContext.Provider value={token}>
      //   <TopHolderV2Context.Provider value={context}>
      //     <WalletProfileContext.Provider value={profiles}>
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <HoldingsDisplay
              holdings={commonHoldings}
              title={"Common Assets"}
            />
          </Grid>
          <Grid item xs={12}>
            <HoldingsDisplay
              holdings={uniqueHoldings}
              title={"Unique Assets"}
            />
          </Grid>
        </Grid>
      </Container>
      //     </WalletProfileContext.Provider>
      //   </TopHolderV2Context.Provider>
      // </TokenContext.Provider>
    );
  }
};
