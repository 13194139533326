import { Link, useTheme } from "@mui/material";
import VuiTypography from "components/VuiTypography";
import { formatAddress } from "utils/formatter";

// {/* <VuiTypography variant="subtitle">
//   <a href={`/wallet/${topHolder.address}`}>
//     {topHolder.walletProfile.name
//       ? topHolder.walletProfile.name
//       : formatAddress(topHolder.address)}
//   </a>
// </VuiTypography>; */}

export const KMLink = ({ path = "", name = "", local = null }) => {
  //   const baseUrl = "https://etherscan.io/";
  //   const url = baseUrl + path + "/" + name;
  const theme = useTheme();
  return (
    <VuiTypography variant="subtitle">
      <Link
        sx={{ color: `${theme.palette.primary.main}` }}
        href={path}
        target={local ? "" : "_blank"}
      >
        {name}
      </Link>
      {/* <a href={url} target="_blank"></a> */}
      {/* <a href={url} target="_blank">
        {formatAddress(name)}
      </a> */}
    </VuiTypography>
  );
};
