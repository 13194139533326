import { Tooltip } from "@mui/material";

const wealthConfig = {
  Whale: {
    src: "blue-whale-icon.png",
    tip: "A Whale is an individual with $25m+ in assets",
  },
  Shark: {
    src: "shark-icon.png",
    tip: "A Shark is an individual that has between $5m and $25m in assets",
  },
  Dolphin: {
    src: "dolphin-icon.png",
    tip: "A Dolphin is an individual that has between $250k and $5m in assets",
  },
  Fish: {
    src: "fish-icon.png",
    tip: "A Fish is an individual that has between $50k and $250k in assets",
  },
  Squid: {
    src: "squid-icon.png",
    tip: "A Fish is an individual that has between $0 and $50k in assets",
  },
  0: {
    src: null,
    tip: "Unknown wallet",
  },
  null: {
    src: null,
    tip: "Unknown wallet",
  },
};

export const WealthIcon = ({ name }) => {
  // console.log(name, " name ");
  const config = wealthConfig[name];
  const imgSrc = config.src;

  if (imgSrc) {
    return (
      <Tooltip title={config.tip}>
        <img
          src={`/static/${imgSrc}`}
          // src={"/static/fish-icon.png"}
          width={50}
          height={50}
          style={{
            borderRadius: "100%",
            marginRight: "10px",
            verticalAlign: "middle",
          }}
        />
      </Tooltip>
    );
  }
};
