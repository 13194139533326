/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

/**
 * The base typography styles for the Vision UI Dashboard  Material.
 * You can add new typography style using this file.
 * You can customized the typography styles for the entire Vision UI Dashboard  Material using thie file.
 */

// Vision UI Dashboard React Base Styles

import "./typography.css";

// Vision UI Dashboard React Helper Functions
import pxToRem from "../functions/pxToRem";

const baseProperties = {
  fontFamily: '"Source Sans 3", sans-serif',
  // fontFamily: '"Plus Jakarta Display", "Helvetica", "Arial", sans-serif',
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  fontSizeXXS: pxToRem(10.4),
  fontSizeXS: pxToRem(12),
  fontSizeSM: pxToRem(14),
  fontSizeRegular: pxToRem(14),
  // fontSizeRegular: pxToRem(16),
  fontSizeLG: pxToRem(18),
  fontSizeXL: pxToRem(20),
};

const baseHeadingProperties = {
  fontFamily: baseProperties.fontFamily,
  fontWeight: baseProperties.fontWeightMedium,
};

const baseDisplayProperties = {
  fontFamily: baseProperties.fontFamily,
  fontWeight: baseProperties.fontWeightLight,
  lineHeight: 1.2,
};

const typography = {
  fontFamily: baseProperties.fontFamily,
  fontWeightLight: baseProperties.fontWeightLight,
  fontWeightRegular: baseProperties.fontWeightRegular,
  fontWeightMedium: baseProperties.fontWeightMedium,
  fontWeightBold: baseProperties.fontWeightBold,

  h1: {
    fontSize: pxToRem(48),
    lineHeight: 1.25,
    ...baseHeadingProperties,
  },

  h2: {
    fontSize: pxToRem(36),
    lineHeight: 1.3,
    ...baseHeadingProperties,
  },

  h3: {
    fontSize: pxToRem(30),
    lineHeight: 1.375,
    ...baseHeadingProperties,
  },

  h4: {
    fontSize: pxToRem(24),
    lineHeight: 1.375,
    ...baseHeadingProperties,
  },

  h5: {
    fontSize: pxToRem(20),
    lineHeight: 1.375,
    ...baseHeadingProperties,
  },

  h6: {
    fontSize: pxToRem(16),
    lineHeight: 1.625,
    ...baseHeadingProperties,
  },

  lg: {
    fontSize: pxToRem(18),
    lineHeight: 1.625,
    ...baseHeadingProperties,
  },

  xxs: {
    fontSize: pxToRem(10),
    lineHeight: 1.625,
    ...baseHeadingProperties,
  },

  p: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeRegular,
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: 1.625,
    ...baseHeadingProperties,
  },

  masterHeading: {
    fontFamily: baseProperties.fontFamily,
    fontSize: pxToRem(48),
    fontWeight: baseProperties.fontWeightBold,
    lineHeight: 1.625,
  },
  mainHeading: {
    fontFamily: baseProperties.fontFamily,
    fontSize: pxToRem(24),
    fontWeight: baseProperties.fontWeightBold,
    lineHeight: 1.625,
  },
  subtleHeading: {
    fontFamily: baseProperties.fontFamily,
    fontSize: pxToRem(16),
    fontWeight: baseProperties.fontWeightBold,
    lineHeight: 1.625,
  },
  componentHeading: {
    fontFamily: baseProperties.fontFamily,
    fontSize: pxToRem(18),
    fontWeight: baseProperties.fontWeightBold,
    lineHeight: 1.625,
  },

  subtext: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeRegular,
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: 1.6,
  },

  cardContent: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeSM,
    fontWeight: baseProperties.fontWeightLight,
    lineHeight: 1.625,
    ...baseHeadingProperties,
  },

  body1: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeXL,
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: 1.625,
  },

  body2: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeRegular,
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: 1.6,
  },

  button: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeSM,
    fontWeight: baseProperties.fontWeightBold,
    lineHeight: 1.5,
    textTransform: "uppercase",
  },

  caption: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeXS,
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: 1.25,
  },

  overline: {
    fontFamily: baseProperties.fontFamily,
  },

  d1: {
    fontSize: pxToRem(80),
    ...baseDisplayProperties,
  },

  d2: {
    fontSize: pxToRem(72),
    ...baseDisplayProperties,
  },

  d3: {
    fontSize: pxToRem(64),
    ...baseDisplayProperties,
  },

  d4: {
    fontSize: pxToRem(56),
    ...baseDisplayProperties,
  },

  d5: {
    fontSize: pxToRem(48),
    ...baseDisplayProperties,
  },

  d6: {
    fontSize: pxToRem(40),
    ...baseDisplayProperties,
  },

  size: {
    xxs: baseProperties.fontSizeXXS,
    xs: baseProperties.fontSizeXS,
    sm: baseProperties.fontSizeSM,
    regular: baseProperties.fontSizeRegular,
    lg: baseProperties.fontSizeLG,
    xl: baseProperties.fontSizeXL,
  },

  lineHeight: {
    sm: 1.25,
    regular: 1.5,
    lg: 2,
  },
};

export default typography;
