import * as React from "react";
import { Grid, Card, CircularProgress } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

import { ethers } from "ethers";

import { timeSince } from "utils/timeSince";
import { DetailCard } from "modules/common/DetailCard";

export const TopHolderControl = ({ topHolderControl, topHoldersTracked }) => {
  return (
    <Card>
      <Grid container alignItems="center">
        <VuiTypography variant="componentHeading">
          Top Holder Control
        </VuiTypography>
        <Grid item xs={12}>
          <Card>
            <VuiBox
              sx={{
                position: "relative",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress
                variant="determinate"
                value={topHolderControl}
                size={
                  window.innerWidth >= 1024
                    ? 200
                    : window.innerWidth >= 768
                    ? 170
                    : 200
                }
              />
              <VuiBox
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <VuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <VuiTypography variant="p" mb="4px">
                    Top {topHoldersTracked} hold
                  </VuiTypography>
                  <VuiTypography
                    variant="h1"
                    fontWeight="bold"
                    mb="4px"
                    sx={({ breakpoints }) => ({
                      [breakpoints.only("xl")]: {
                        fontSize: "32px",
                      },
                    })}
                  >
                    {topHolderControl.toFixed(2)}%
                  </VuiTypography>
                  <VuiTypography variant="p">of the supply</VuiTypography>
                </VuiBox>
              </VuiBox>
            </VuiBox>
          </Card>
        </Grid>
        {/* <Grid item xs={12}>
          <DetailCard
            title={"Tokens Held"}
            subtitle={ethers.utils.commify(whaleHoldings.toFixed())}
          />
        </Grid> */}
      </Grid>
    </Card>
  );
};
